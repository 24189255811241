/**
 * data-solution-accordion.
 */

// Module styles
import './_data-solution-accordion.scss';

// Module template
import './_data-solution-accordion.twig';

export const name = 'data-solution-accordion';

export const defaults = {
  dummyClass: 'js-data-solution-accordion-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */

(function ($, $context, { dataSolutionAccordion = {} } = {}) {
  // Find our component within the DOM
  const $dataSolutionAccordion = $('.data-solution-accordion', $context);
  // Bail if component does not exist
  if (!$dataSolutionAccordion.length) {
    return;
  }
  // Merge defaults with incoming settings
  const settings = {
    ...defaults,
    ...dataSolutionAccordion,
  };
  $dataSolutionAccordion.addClass(settings.dummyClass);
  const $dataAccordion = $('.data-solution-accordion');
  $dataAccordion
    .find(
      '.data-solution-accordion__content-list .data-solution-accordion__left-col'
    )
    .each(function () {
      $(this).on('click mouseenter keydown', function () {
        const hoverIndex = $(this).index();
        $(this).addClass('active');
        $(this).siblings().removeClass('active');
        const $imageListItems = $(this)
          .parents('.data-solution-accordion')
          .find(
            '.data-solution-accordion__image-list > .data-solution-accordion__right-col'
          );
        $imageListItems.removeClass('active');
        $imageListItems.eq(hoverIndex).addClass('active');
      });
    });
})(jQuery);
